import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'

import './about.scss'


const AboutGretchenJonesPage = (props) => (
  <Layout location={props.location}>
    <SEO title="G Jones for Hormones" />
    <Container>
      <Row style={{ marginTop: '3rem' }} className="justify-content-center">
        <Col>
          <h1>I’m Gretchen Jones</h1>
          <section className="clearfix">
            <Img
              className="img-fluid rounded float-md-right ml-md-4 mb-4 d-none d-md-flex"
              fluid={props.data.image1.childImageSharp.fluid}
              alt="Picture of Gretchen Jones"
              style={{
                width: '100%',
                maxHeight: '500px',
                maxWidth: '375px'
              }}
            />
            <p>
              I became a 36-year-old widow in 2001 and had just started physician assistant medical school training when my husband died. We had two children ages 9 and 5 when he passed away. In 2003, I finished my education and residency program and began working full time. I found love again and had two more children in 2005 and 2007 when I was 40 and 42. Then in 2008, symptoms began. I was having heart palpitations, extreme insomnia, migraine headaches, low libido, joint aches, brain fog, hair loss, dry skin, dry eyes, weight gain around my midsection, crepey skin on my abdomen, mood swings with anxiety and panic attacks, and just overall felt irritable and horrible. I do what I do now because I’ve come out on the other end and have completely wiped out all those symptoms I listed above and have maintained health and vitality since my journey began in 2008, over ten years ago. Let me show you how you can do the same, so it inspires you to take care of your health and hormones too.
            </p>
            <Img
              className="img-fluid rounded float-md-right ml-md-4 mb-4 d-md-none"
              fluid={props.data.image1.childImageSharp.fluid}
              alt="Picture of Gretchen Jones"
              style={{
                width: '100%',
                maxHeight: '500px',
                maxWidth: '375px'
              }}
            />
            <h2>Here is the short story</h2>
            <p>
              I am a Certified Physician Assistant accredited by the National Committee on Certification of Physician Assistants (NCCPA). I received my education from Midwestern University in Downers Grove Illinois Campus. Before becoming a physician assistant, I was a financial analyst in a fast paced commercial real estate career for CB Commercial and mother of two young boys. I decided to quit my job to go into healthcare and went to school for an emergency medical technician and did volunteer work while returning back to University for undergraduate pre-med training in Exercise Physiology. I got my bachelor of science in Exercise Physiology with a minor in Organic Chemistry in 1999 and applied to one PA school in 2000 and started classes 2001.
            </p>
            <h2>Life’s Journey</h2>
            <p>
              My own life has not been easy. I have always enjoyed good health and was not very familiar with the medical world since I was usually healthy. However, when my hormones began to decline, the medical world failed me and almost made me worse.
            </p>
            <div className="timeline">
              <div className="timeline-container timeline-left">
                <div className="timeline-content">
                  <h2>2001</h2>
                  <p>I became a widow</p>
                </div>
              </div>
              <div className="timeline-container timeline-right">
                <div className="timeline-content">
                  <h2>2007</h2>
                  <p>
                    Extreme Migraines, Fatigue, Insomnia, Heart Palpitations... the list goes on and on.
                  </p>
                  <p>
                    However, I was diagnosed with hypothyroidism and started on thyroid medication, I was having irregular heartbeats and heart palpitations and started on beta blockers, terrible migraine headaches and was prescribed strong migraine medications, horrible insomnia and was prescribed sleeping medications, benzos, and antidepressants.
                  </p>
                </div>
              </div>
              <div className="timeline-container timeline-left">
                <div className="timeline-content">
                  <h2>2008</h2>
                  <p>I started looking into hormones because I wasn’t doing well with the pharmaceutical options given to me. This is when I began to replace my hormones with cyclic hormone therapy.</p>
                </div>
              </div>
              <div className="timeline-container timeline-right">
                <div className="timeline-content">
                  <h2>2015</h2>
                  <p>
                    In 2015, I lost my son, Danny, age 22 to suicide. I also was going through a divorce AND soon to be ex-husband was spreading terrible rumors to anyone who would listen to get me fired from a new job and destroy my career.
                  </p>
                  <p>
                    As you can imagine, I was under terrible stress, single parent with three boys still at home with one headed to college the coming year and I had to find another job, grieve the loss of my son, and hold it together. I cannot even imagine my life if I had not been taking hormones at this time. Every part of my journey has made me wiser, kinder and more patient and given me insight that only experience can.
                  </p>
                </div>
              </div>
              <div className="timeline-container timeline-left">
                <div className="timeline-content">
                  <h2>2019</h2>
                  <p className="lead">I feel better today as a 53-year-old woman than I did in my twenties.</p>
                  <p>
                    As part of this exciting, often frustrating and self-discovering journey, I founded FixAFlash, a safe place where women and men can discuss hormone therapy and symptoms and ask questions and a place where education and knowledge can be shared among like-minded individuals.
                  </p>
                  <p>
                    If you came to this website, you are probably seeking help with your hormones and still have questions or want a better answer. I’m glad you did. My practice is driven by my passion for giving you hope, knowledge, and direction to self-heal, so you get your energy, hormones, weight, hair, skin, sleep, beauty, joy, and optimism where you always wanted them to be. One of the first things I will offer right off the bat is for you to feel hope and trust that you are at the right place. Spend an hour with me one on one, no charge, to discuss your journey.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
    <ScheduleConsultation />
  </Layout>
)

export default AboutGretchenJonesPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "about/gretchen-jones.jpg" }) {
      ...profileImage
    }
  }
`
